import {IActionButton, ICreateComponent, } from '../../shared/interfaces/ui';
import {MasterDataService} from '../../services/masterdata/masterdata.service';
import {ActivatedRoute, Router} from '@angular/router';
import {FormBuilder} from '@angular/forms';
import {ActionBarService} from '../../core/services/action-bar/action-bar.service';
import {DataDetailBase} from '../../shared/classes/data-detail-base';
import {AuthorizationService} from '../../services';
import {DatatableService} from '../../services/datatable/datatable.service';
import {BreadcrumbsService} from '../../core/services/breadcrumbs/breadcrumbs.service';
import {TranslateService} from '@ngx-translate/core';
import {AlertBlockService} from '../../core/services/alert-block/alert-block.service';
import {ToasterService} from 'angular2-toaster';

export class MasterDataDetail extends DataDetailBase {

  hasParams: boolean;

  config: ICreateComponent = {
    title: 'Detail',
    resource: 'contact',
    editMode: false,
    resourceIdName: 'contactId',
    resourceId: 0
  };

  protected addButtons: IActionButton[] = [
    {
      label: 'BUTTON.editMode',
      type: 'button',
      class: 'btn-primary',
      permission: 'UpdateContact',
      icon: 'fa-edit',
      callback: this.editMode.bind(this)
    }
  ];

  protected viewButtons: IActionButton[] = [
     {
      label: 'BUTTON.viewMode',
      type: 'button',
      class: 'btn-primary',
      permission: 'UpdateContact',
      icon: 'fa-eye',
      callback: this.viewMode.bind(this)
    },
  ];

  constructor(protected datatableService: DatatableService,
              protected toasterService: ToasterService,
              protected breadcrumbsService: BreadcrumbsService,
              protected _fb: FormBuilder,
              protected translate: TranslateService,
              protected router: Router,
              protected route: ActivatedRoute,
              protected actionBarService: ActionBarService,
              protected authService: AuthorizationService,
              protected alertBlockService: AlertBlockService,
              protected masterDataService: MasterDataService) {
    super(datatableService, toasterService, breadcrumbsService, _fb, translate, router, route, actionBarService, authService, alertBlockService);
    this.actionBarService.setActionButtons(this.addButtons);
    this.route.params
      .subscribe(params => {
        for (const obj in params) {
          if (params.hasOwnProperty(obj)) {
            this.config.resourceId = params[obj];
          }
        }
      });
  }

  setRouteState() {
    this.route.queryParams.forEach(({ edit }) => {
      this.hasParams = !!edit;
      this.config.editMode = !!edit;
      this.config.viewMode = !this.config.editMode;
      if (this.config.editMode) {
        this.editMode();
      } else {
        this.viewMode();
      }
    });
  }

  editMode() {
    this.actionBarService.setActionButtons(this.viewButtons);
  }

  viewMode() {
    this.actionBarService.setActionButtons(this.addButtons);
  }

  clearURL() {
    const path = this.router.url.replace(/\?.*/, '');
    this.router.navigate([path]);
  }

  setEditParam() {
    if (!this.hasParams) {
      this.router.navigate([this.router.url], { queryParams: {edit: 1} });
    }
  }

}
