import { Injectable } from '@angular/core';
import { HttpRequestService } from '../http-request/http-request.service';
import {HttpParams, HttpResponse} from '@angular/common/http';
import {Location} from '../../shared/models/tenant/location';
import {of} from 'rxjs/observable/of';
import {Subject} from 'rxjs/Subject';
import {map, tap} from 'rxjs/operators';
import {IFilterField, IOperation, IPageableReq, IPaging, ISortObject} from '../../shared/interfaces/http';
import {Address} from '../../shared/models/tenant/address';
import {UtilsService} from '../../shared/utils/utils.service';

@Injectable()
export class LocationService {
  apiLocationControllerPath = 'location';
  locations: IPageableReq;
  locations$: Subject<any> = new Subject<any>();

  constructor(private apiService: HttpRequestService,
                        private utilsService: UtilsService) {
  }

  subscription() {
    return this.locations$.asObservable();
  }

  addLocationToLocal(location: Location) {
    this.locations.items.push(location);
    this.locations.paging.totalItems++;
    this.locations$.next(this.locations);
  }

  putLocationToLocal(location: Location) {
    const index = this.locations.items.findIndex(el => el.locationId === location.locationId);
    this.locations.items[index] = location;
    this.locations$.next(this.locations);
  }

  getLocation(locationId: number) {
    return this.apiService.get<Location>([this.apiLocationControllerPath, locationId].join('/'))
      .pipe(map((response: HttpResponse<Location>) => response.body));
  }

  getLocations(params: HttpParams = null, sortOptions: ISortObject  = { sortField: null, sort: null }) {
    const query = this.utilsService.queryBuilder([
      { key: 'sortField', value: sortOptions.sortField },
      { key: 'sort', value: sortOptions.sort },
    ]);

    this.apiService.get<any>([this.apiLocationControllerPath, query].join('/'), null, params)
      .pipe(map((response: HttpResponse<any>) => response.body),
        tap(response => this.locations = response))
      .subscribe(response => {
        this.locations$.next(response);
      });
  }

  filterByFields(filter: Array<IFilterField>, paging: IPaging, operation: IOperation['type'] = 'All', sortOptions: ISortObject = { sortField: null, sort: null } ) {
    const query = this.utilsService.queryBuilder([
      { key: 'operation', value: operation},
      { key: 'sortField', value: sortOptions.sortField },
      { key: 'sort', value: sortOptions.sort },
      { key: 'pageSize', value: paging.pageSize },
      { key: 'pageNumber', value: paging.pageNumber },
    ]);
    return this.apiService.post<Address>([this.apiLocationControllerPath, 'filter', query].join('/'), filter)
      .pipe(map((response: HttpResponse<any>) => response.body ),
        tap(response => this.locations = response))
      .subscribe(response => this.locations$.next(response));
  }

  getLocationById(params: HttpParams = null, resource: string, resourceId: number, operation: IOperation['type'] = 'All') {
    return this.apiService.get<any>([resource, resourceId, this.apiLocationControllerPath, '?operation=' + operation].join('/'), null, params)
      .pipe(map((response: HttpResponse<any>) => response.body ))
  }

  postLocation(data: Location) {
    const { tenantId, rowVersion, name, geoLatitude, geoLongitude } = data;
    return this.apiService.post(this.apiLocationControllerPath, { tenantId, rowVersion, name, geoLatitude, geoLongitude })
      .pipe(map((response: HttpResponse<any>) => {
        return response.body;
      }));
  }

  putLocation(data: Location) {
    return this.apiService.update([this.apiLocationControllerPath, data.locationId].join('/'), data)
      .pipe(map((response: HttpResponse<any>) => {
        return response;
      }));
  }

  deleteLocation(data: Location) {
    return this.apiService.delete(this.apiLocationControllerPath, '' + data.locationId)
      .pipe(map((response: HttpResponse<any>) => {
        return response;
      }));
  }
}
