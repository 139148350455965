import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { HttpRequestService } from '..';
import { FilledFormDto } from '../../shared/models/server/DataTransferObject/Objects/FilledForms';

@Injectable()
export class FilledFormService {

  constructor(
    private apiService: HttpRequestService
  ) { }

  public get(filledFormId: number) : Promise<FilledFormDto> {
    return this.apiService
        .get<FilledFormDto>(['filledform', filledFormId.toString()].join('/'))
        .pipe(map((response: HttpResponse<FilledFormDto>) => response.body))
        .toPromise();
  }
}
