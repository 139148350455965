import {forwardRef, Inject, Injectable} from '@angular/core';
import { UtilsService } from '../../shared/utils/utils.service';
import { StereotypeService } from '../stereotype/stereotype.service';
import { HttpRequestLoginService } from '../http-request-login/http-request-login.service';
import { DatatableServiceBase } from './datatable-base.service';
import {HttpRequestService} from '../';

@Injectable()

export class IdentityDatatableService extends DatatableServiceBase {

  constructor(@Inject(forwardRef(() => HttpRequestLoginService))
              protected apiService: HttpRequestLoginService,
              protected stereotypeService: StereotypeService,
              protected utilsService: UtilsService) {
    super(apiService, stereotypeService, utilsService);
    this.fetchStereotypes();
  }

}
