import { Injectable } from '@angular/core';
import {UtilsService} from '../../shared/utils/utils.service';
import {Subject} from 'rxjs/Subject';
import {HttpRequestService} from '../index';
import {map} from 'rxjs/operators';
import {HttpParams, HttpResponse} from '@angular/common/http';
import {IFilterField} from '../../shared/interfaces/http';

@Injectable()
export class LocationGroupService {
  apiLocationGroupControllerPath = 'locationgroup';
  _subscribe: Subject<any> = new Subject<any>();

  constructor(private apiService: HttpRequestService) { }

  subscription$() {
    return this._subscribe.asObservable();
  }

  getLocationsByLocationGroupId(params: HttpParams = null, id: number) {
    return this.apiService.get([this.apiLocationGroupControllerPath, id, 'location'].join('/'), null, params)
      .pipe(map((response: HttpResponse<any>) => response.body));
  }

  filterLocationsByFieldsByLocationGroupId(id: number, filter: Array<IFilterField>) {
    return this.apiService.post<any>([this.apiLocationGroupControllerPath, id, 'location', 'filter'].join('/'), filter)
      .pipe(map((response: HttpResponse<any>) => {
        return response.body; }));
  }

  removeContactFromGroup(locationGroupId: number, locationId: number) {
    return this.apiService.delete<any>([this.apiLocationGroupControllerPath, locationGroupId, 'location'].join('/'), locationId + '');
  }

  addLocationToGroup(locationGroupId: number, locationId: number) {
    return this.apiService.post<any>([this.apiLocationGroupControllerPath, locationGroupId, 'location', locationId].join('/'), {});
  }
}
